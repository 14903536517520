import React, { useState, useEffect } from "react";
import { PageWrapper } from "~components/Core";

import HeaderButton from "~sections/marketing/Header";
import FooterSix from "~components/Footer";

import avatar from "../../assets/image/avatar.svg";
import SEO from "../../components/Seo";


import { Container, Row, Col, Spinner } from "react-bootstrap";
import { Box, Heading, Button, Paragraph } from "~styled";
import GooglePlacesAutocomplete, { getLatLng, geocodeByAddress } from 'react-google-places-autocomplete';

import styled from 'styled-components'

import { Form, Link } from "~components";

const axios = require('axios');

const WorkerBox = styled(Box)`
border-radius:20px;
padding: 15px;
box-shadow: 0 0.5em 2em 0em;

@media (max-width: 575px) {
  box-shadow: 0 0.1em 2em 0em;
  margin-left: 15px;
  margin-right: 15px;

}

height: 230px;
overflow: hidden;
.img-col{
    position: relative;
    max-height: 100%;
}

img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
}


.title{
    font-size: 20px;
    line-height: 25px;
    font-weight: bold;
}

.info-col{
    max-height: 100%;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
}
`

const StyledSearch = styled.div`
padding-top: 90px;
background-color: #FAFAFA;
@media (min-width: 768px){
    padding-top: 80px;
}

@media (min-width: 992px){
    padding-top: 100px;
}


display: flex;
h1{
   color: #767676;
   font-size: 20px;
   line-height: 25px;
   @media (max-width: 575px) {
       font-size: 15px;
       line-height: 20px;
   }
   margin-bottom: 20px;
   
}

`


const SearchButton = styled(Button)`
  min-width: 180px;
  height: 56px;
  font-size: 15px;
  transition: 0.4s;
  &.btn-1 {
    box-shadow: -12px 12px 50px rgb(96 1 211 / 30%);
    &:hover {
      box-shadow: -12px 12px 50px rgb(96 1 211 / 0%);
    }
  }
`;
const SearchNewsletterText = styled(Box)`
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 30px;
  margin-top: 25px;
  @media (min-width: 768px) {
    color: #828288;
  }
`;
const SearchNewsletter = styled(Box)`
  max-width: 100%;
  position: relative;
  .icon {
    top: 50%;
    position: absolute;
    left: 25px;
    font-size: 18px;
    transform: translateY(-50%);
    color: #5bd2ff;
  }
  .form-control {
    width: 100%;
    border-radius: 20px;
    min-height: 72px;
    padding-left: 55px;
  }
  .btn {
    min-width: 100px;
    height: 56px;
    color: #fff;
    background-color: #5bd2ff;
    border: none;
    margin-top: 8px;
    @media (min-width: 480px) {
      position: absolute;
      top: 0px;
      right: 8px;
    }
  }
`;
const SearchNewsletterButton = styled(Button)`
border-radius: 20px !important;
background-color: white !important;
color: #5BD2FF !important;
border: 1px solid #5BD2FF !important;

`;

const SearchBox = styled(Box)``;


const StyledText = styled(Paragraph)`
  color: black;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 17px;

  text-align:left;

  &:last-child {
    margin-bottom: 0;
  }

`;

const StyledResults = styled.div`

.loading{
  color: #28465e;
}

.result-container{
  min-height: 46vh;
  position: relative;
}


.loading-row{
  text-align: center;
  height: 45vh;
  display: flex;
  align-items: center;
  justify-content: center;

}
  padding-top: 2rem;
  padding-bottom: 2rem;

`


function BlankImage({ }) {
  return (
    <Box style={{ width: "100%", height: "100%" }} bg="#707070">
      <svg class="MuiSvgIcon-root MuiAvatar-fallback" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg>

    </Box>
  );
}



function Worker({ data }) {
  return (
    <Col xs={12} xl={4}>
      <WorkerBox>
        <Row css={{ height: "100%" }}>
          <Col xs={5} className='img-col'>
            {data.avatar
              ? <img src={`${data.avatar}`}></img>
              : <img style={{ objectFit: "scale-down" }} src={avatar} />
            }
          </Col>
          <Col xs={7} className='info-col'>
            <StyledText className='title'>{data.name_worker}</StyledText>
            <StyledText>{data.description}</StyledText>

          </Col>
        </Row>
      </WorkerBox>
    </Col>
  );
}

const StyledAutocomplete = styled(GooglePlacesAutocomplete)`
div{
  padding: 12px 18px;
}


`


export default function IndexPage({ data, location }) {


  const [workers, setWorkers] = useState([]);
  const [locationVal, setLocation] = useState("");
  const [value, setValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pleaseStart, setPleaseStart] = useState(true);
  const [url, setUrl] = useState("");

  function getLocation(value) {

    setPleaseStart(false);
    setIsLoading(true);
    setLocation(value['label']);

    geocodeByAddress(value['label'])
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) =>
        getWorkers(lat, lng)
      );

  }

    useEffect(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const url_location = urlParams.get('location')
      
      if (url_location){
        setPleaseStart(false);
        setIsLoading(true);
        setLocation(url_location);

        geocodeByAddress(url_location)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) =>
          getWorkers(lat, lng)
        );
  

      }
    }, [])
  
  



  function getWorkers(lat, lng) {
    axios.get('https://careaidaustralia.com.au/worker/search', {
      params: {
        lat: lat,
        lng: lng
      }
    })
      .then(function (response) {
        setIsLoading(false);

        setWorkers(response.data.data)
      })
      .catch(function (error) {
      })
      .then(function () {
        // always executed
      });


  }


  const header = {
    headerClasses: `site-header site-header--menu-start light-header site-header--sticky`,
    containerFluid: false,
    darkLogo: false,

    // customLogo: Images.HeaderLogo,
    buttonBlock: (
      <HeaderButton
        className="ms-auto d-none d-xs-inline-flex"
        btnOneText="GET STARTED"
        mr="15px"
        mrLG="0"
      />
    ),
  };

  return (
    <PageWrapper headerConfig={header}>
      <SEO title="Search" description="Your search for a Care Aid worker starts here!"/>

      <StyledSearch>
        <Container>


          <div>
            <SearchNewsletter mb="20px">


              <SearchBox>
                <i className="fas fa-search icon" />
                <StyledAutocomplete apiKey="AIzaSyAaap3I7wYaDquUD8ByFPTS6m9c-WuXoDo" 
                apiOptions={{ language: 'en', region: 'au' }} 
                selectProps={{
                  locationVal,
                  noOptionsMessage: () => "Search suburb / postcode",
                  placeholder:"Search for Support Workers",
                  onChange: getLocation,
                  styles: {
                    input: (provided) => ({
                      ...provided,
                    }),
                    option: (provided) => ({
                      ...provided,
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                
                    }),
                  },
                }}
                
                />
              </SearchBox>
            </SearchNewsletter>


          </div>

          <div className='text-white'>

            {workers.length > 1 &&
              <h1>{workers.length} support workers found near {locationVal}</h1>
            }
            {workers.length == 1 &&
              <h1>{workers.length} support worker found near {locationVal}</h1>
            }

          </div>

        </Container>
      </StyledSearch>
      <StyledResults bg="white">
        <Container className='result-container'>

          {isLoading &&
          <Row className='loading-row'>
            <Spinner animation="border" role="status" className='loading'>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Row>
          } 
          {pleaseStart &&
          <Row className='loading-row'>
              <Heading>
                Please enter a suburb / postcode in the search box above to begin.
              </Heading>
          </Row>
          } 

        {!pleaseStart && !isLoading && workers.length == 0 &&
          <Row className='loading-row'>
              <Heading>
              Unfortunately your request returned no results .
              </Heading>
          </Row>
          } 


          {!isLoading &&

          <Row>
            {workers.map((item, index) => {
              return <Worker data={item} key={`worker-${index}`} />;
            })}

          </Row>
          }


        </Container>

      </StyledResults>

      <FooterSix />
    </PageWrapper>
  );
}

